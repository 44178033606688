import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { environment } from '../../../../environments/environment';
import { NavigationService } from '../../../services/navigation.service';
import { BookingService } from '../../../services/booking.service';
import { SustainabilityService } from '../../../services/sustainability.service';
import { GoogleAnaylticsService } from 'src/app/services/google-anayltics.service';
import { Subject, Subscription } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/user-management.service';
import { HttpClient } from '@angular/common/http';
import { EtbInternalService } from 'src/app/services/etb-internal.service';
export interface MenuOption {
  iconName: string,
  displayName: string,
  selected: boolean,
  path: string,
  setSelectedStatusOnClick?: boolean,
  badgeNumber?: number,
  show?: boolean
}

@Component({
  selector: 'app-side-nav-content',
  templateUrl: './side-nav-content.component.html',
  styleUrls: ['./side-nav-content.component.scss']
})



export class SideNavContentComponent implements OnInit, OnChanges {
  bookingFilterRegex = environment.bookingFilterRegex
  selectedItem = 'My Bookings';
  isChaterer: boolean;
  navType = environment.navType;
  charBrokerLength: any;
  pages: any[] = [];
  pages_bottom: any[] = [];
  module: any = [];
  skelton: boolean = true;
  profileData: {
    'company_no': string, 'charterer'?: string, 'email'?: string, 'family_name'?: string, 'given_name'?: string, 'idtyp'?: string, 'name': string, 'max_days': number,
    'IsApiDocumentation': any,'user_id':any,
    'role'?: string,
    'min_days': number, 'nick'?: string, isBroker: any, broker_short_name: any, isDeleted: number, status: boolean
  } = {
      'charterer': '', 'company_no': '', 'email': '', 'family_name': '', 'given_name': '', 'idtyp': '',
      'name': '', 'max_days': 3, 'min_days': 1, 'nick': '', 'isBroker': '', 'broker_short_name': '', 'isDeleted': 1,status:true ,IsApiDocumentation: '','user_id':'',
    }

  downloadSubscription: Subscription;
  downloadPocSubscription: Subscription;
  downloadBOokingSubscription: Subscription
  isDownloadBookingKey: any;
  isDownloadSustainBookingKey: any;
  myInterval: any;
  isDownloadPocZipKey: string | null;
  private readonly _destroying$ = new Subject<void>();
  isDownloadKookingKey: string | null;
  stoltDomain: any;
  IsApiDocumentation: boolean = true;
  isAdminSuperAdmin: boolean = false;
  unreadBooking: number = 0;
  totalAlertCount: number = 0;
  userCountry: any = null;
  userIpUrl = environment.IP_URL;
  userEmailArr:any = environment.USERSEMAIL
  menuOptions: MenuOption[] = []

  bottomOptions: MenuOption[] = []

  signOutOption: MenuOption = {
    "iconName": "fa-solid fa-sign-out",
    "displayName": "Sign Out",
    "selected": false,
    "path": "logout",
    "setSelectedStatusOnClick": false
  }

  userOption: any = ''
  @Input() navigationUrl: any
  public isCollapsed:boolean = false;
  isBookingStatus: boolean;
  useremail: any;
  getDomain:any;
  showMonkeySurvey: boolean;
  userId:any;
  isPreview:boolean;
  title:any;
  editorData:any;
  isShowAncmt:any;
  isAnnouncementClosed:any;
  constructor(private router: Router,
    private authService: MsalService,
    private navigationService: NavigationService,
    private bookingService: BookingService,
    private sustainabilityService: SustainabilityService,
    private _googleAnaylticsService: GoogleAnaylticsService,
    public toastr: ToastrService,
    private msalBroadcastService: MsalBroadcastService,
    private userManagementService: UserManagementService,
    private httpClient: HttpClient,
    private _storageService: StorageService,
    private etbInternalService: EtbInternalService) {
    
    this.navigationService.getUserInfo().subscribe((res: any) => {
      if (res && res.data) {
        this.isShowAncmt = localStorage.getItem('show_announcement');
        this.isAnnouncementClosed = res.data.isAnnouncementClosed;
        this.getDomain = res.data.email.substring(res.data.email.indexOf('@') + 1);
        localStorage.setItem('mailDomain', this.getDomain);
        this.userManagementService.userRole = res.data.role;
        localStorage.setItem('user_id', res.data.user_id)
        localStorage.setItem('modules', JSON.stringify(res.data.module))
        localStorage.setItem('userInfo', JSON.stringify(res.data));
        this.bookingService.isSurveyFeedback.next(res.data.isSurveyFeedback);
        this.profileData = res.data;
        this.charBrokerLength = this.profileData.broker_short_name?.length
        const d_lay = { 'min_days': res.data.min_delay_days, 'max_days': res.data.max_delay_days }
        localStorage.setItem('d_lay', JSON.stringify(d_lay));
        if (res?.data?.surveyOpenCount < 3 && !this.navigationService.isSurveyMonkey && !res?.data?.isSurveySubmitted) {
          let isStoltDomain = res.data.email.toLowerCase().includes('@stolt');
          let isSuperAdmin = res.data.role === 'super_admin';
          if (isStoltDomain && isSuperAdmin || !isStoltDomain) {
            this.navigationService.isSurveyMonkey = true;
            this.showMonkeySurvey = true;
            this.userId = res.data.user_id;
          } else{
            this.openAnnouncement();
          }
        }else{
          this.openAnnouncement();
        }
      }
    })
    this.getEtbSavedFilters();
  }

  ngOnInit(): void {
    this.loadIp()
    this.getUnreadBookingCount()
    this.getUserInfo();    
    this.checkCompanyAssigned();
    this.checkDwonloadBookingKey();
    this.checkDwonloadPocKey();
    this.checkDwonloadBookingManualKey();
    this.getAlertCount();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit()
  }

  openAnnouncement(){
    if (this.isShowAncmt || (!this.isShowAncmt && !this.isAnnouncementClosed)) {
      this.getActiveAnnouncement();
    }
  }

  onSurveyClosed(value: string) {
    if(value){
      this.openAnnouncement();
    }
  }

  getActiveAnnouncement(){
    this.navigationService.getActiveAnnouncement().subscribe((res: any) => {
       if(res.status == 200){
        this.title = res.data.title;
        this.editorData = res.data.content;
        this.isPreview = true;
       }
    })
  }

  getUnreadBookingCount() {
    this.bookingService.currentBookingCount.subscribe(type => {
      this.getBookingDocCount(type)
    })
  }

  getAlertCount() {
    this.etbInternalService.currentAlertCount.subscribe((res:any) => {
      if(res.action){
        this.totalAlertCount = res.count;
        this.createSideBarOption(this.navigationUrl); // recall side bar for update unread booking count 
      }
    })
  }

  getEtbSavedFilters() {
    this.etbInternalService.getEtbSavedFilters().subscribe({
        next: (res: any) => {
            if (res.status == 200) {
                res.data.voyNums = res.data.voyNums.map(Number);
                res.data.portNums = res.data.portNums.map(Number);
                res.data.berthSeqNums = res.data.berthSeqNums.map(Number);
                let filterData = res.data;
                let payload = {
                  vslCodes: filterData.vslCodes,
                  portNums: filterData.portNums,
                  voyNums: filterData.voyNums,
                  berthSeqNums: filterData.berthSeqNums,
                  alerts: filterData.alerts
              }
              this.etbInternalService.getCouting(payload).subscribe({
                next: (res: any) => {
                    if (res.status == 200) {
                        this.totalAlertCount = res.data.totalCount;
                        this.etbInternalService.changeAlertCount({ count: this.totalAlertCount, action: true })
                    }
                }, error: err => {
                }
            })
            }
        }, error: err => {
            
        }
    })
}

  getUserInfo() {
    this.navigationService.getUserInfo().subscribe((res: any) => {
      this.profileData = res.data;
      this.module = res?.data?.module;
      this.useremail = res.data.email
      if (!this.profileData?.company_no?.length || this.profileData.isDeleted || (!this.profileData.status)) {
        this._storageService.putDataInStorage("isNotCompanyAssigned", true);
        this.router.navigate(['/'])
      } else {
        this._storageService.putDataInStorage("isNotCompanyAssigned", false);
        this.createSideBarOption(this.navigationUrl);
        this.bookingService.updateUserAccessedmodule(this.pages);
      }
      this.stoltDomain = res.data.email.substring(res.data.email.lastIndexOf("@")).substring(0, 6);
      this._storageService.putDataInStorage('stoltDomain', this.stoltDomain);
      this.pushDataToGADataLayer(this.router.url);
      let userName = (res.data.name.replace(this.bookingFilterRegex, "")).split();
      this.profileData.name = userName.length > 1 ? `${userName[0]} ${userName[userName.length - 1]}` : userName[0];
      this.profileData.nick = (this.profileData.name.match(/\b(\w)/g))?.join('');
      const d_lay = { 'min_days': res.data.min_delay_days, 'max_days': res.data.max_delay_days }
      localStorage.setItem('d_lay', JSON.stringify(d_lay));

      this.navigationService.setUpdateUserAccessedmodule(this.profileData?.IsApiDocumentation);
      this._storageService.putDataInStorage('pages', JSON.stringify(this.pages));
      this.getUserProfile()
    })
  }

  createSideBarOption(selectedOpt: any) {
    this.skelton = false;
    this.isAdminSuperAdmin = (this.profileData?.role === "admin" || this.profileData?.role === "super_admin");
    this.defaultMenus(selectedOpt)
    this.moduleAccess(selectedOpt)
  }

  defaultMenus(selectedOpt: any) {
    this.pages = [
      {
        "iconName": "fa-solid fa-magnifying-glass",
        "displayName": "Search",
        "selected": this.selectedMenu(selectedOpt, 'search'),
        "path": "search",
        "show": true
      },
    ]
    if (this.module.includes('Berth Schedule')) {
      let berthObj = {
        "iconName": "fa-regular fa-calendar-minus",
        "displayName": "Berth Schedule",
        "selected": this.selectedMenu(selectedOpt, 'berths-schedules'),
        "path": "berths-schedules",
        "show": true
      }
      this.pages.push(berthObj)
    }

    let bookingObj =  {
      "iconName": "fa-solid fa-book-open",
      "displayName": "My Bookings",
      "selected": this.selectedMenu(selectedOpt, 'booking'),
      "path": "booking",
      "badgeNumber": this.unreadBooking > 0 ? this.unreadBooking : '',
      "show": true
    }
    this.pages.push(bookingObj)
  }

  moduleAccess(selectedOpt: any) {
    if (this.module.includes('Sustainability')) {
      let susObj = {
        "iconName": "fa fa-anchor",
        "displayName": "Sustainability",
        "selected": this.selectedMenu(selectedOpt, 'sustainability'),
        "path": "sustainability",
        "show": true
      }
      this.pages.push(susObj)
    }

    if (this.module.includes('Documentation')) {
      this.documentMenu(selectedOpt)
    }
 
    if (this.module.includes('API Documentation')) {
      let APIDocObj = {
        "iconName": "fa-regular fa-file",
        "displayName": "API Documentation",
        "selected": this.selectedMenu(selectedOpt, 'api/documentation'),
        "path": "api/documentation",
        "show": true
      }
      this.pages.push(APIDocObj)
    }

    if (this.isAdminSuperAdmin) {
      let userObj = {
        "iconName": "fa fa-users",
        "displayName": "User Management",
        "selected": this.selectedMenu(selectedOpt, 'user'),
        "path": "user/user-management",
        "show": true
      }
      this.pages.push(userObj)
    }

     if (this.getDomain == 'STOLT.COM' && this.module.includes('ETBs Internal')) {
      let documentObj = {
        "iconName": "fa-solid fa-ship",
        "displayName": "ETBs Internal",
        "selected": this.selectedMenu(selectedOpt, 'etb-internal'),
        "path": "etb-internal",
        "badgeNumber": this.totalAlertCount > 0 ? this.totalAlertCount : '',
        "show": true
      }
      this.pages.push(documentObj)
    }


    this.bottomOptions = [
      {
        "iconName": "fa-solid fa-gear",
        "displayName": "Settings",
        "selected": this.selectedMenu(selectedOpt, 'setting'),
        "path": "setting"
      }
    ]

      if (this.profileData.role == 'super_admin') {
      let ancObj = {
        "iconName": "fa-solid fa-volume-up",
        "displayName": "Announcements",
        "selected": this.selectedMenu(selectedOpt, 'announcements'),
        "path": "announcements",
        "show": true
      }
      this.bottomOptions.unshift(ancObj)
     }
  }

  documentMenu(selectedOpt:any){
    let documentObj = {
      "iconName": "fa-regular fa-file",
      "displayName": "Documentation",
      "selected": this.selectedMenu(selectedOpt, 'documentation/list'),
      "path": "documentation/list",
      "show": true
    }
    this.pages.push(documentObj)
  }

  getUserProfile() {
    if ((this.profileData.name || this.profileData.charterer)) {
        this.userOption = {
          "displayName": this.profileData.name,
          "designation": this.profileData.charterer ?  this.profileData.charterer[0].toUpperCase() + this.profileData.charterer.slice(1).toLowerCase() : 'Account',
          "initials": this.profileData.nick
      }
    }
  }


  Onclick(link:any)
  {
    this.pushDataToGADataLayer(link);
    this.navigationService.setMobileNav(false);
  }

  getBookingDocCount(type: any) {
    if (this.router.url == '/booking') {
      if (type.bookingType > 0) {
        this.bookingService.getBookingDocCount(type.bookingType).subscribe({
          next: res => {
            if (res.data) {
              this.unreadBooking = res.data.unreadBookingCount
              this.createSideBarOption(this.navigationUrl); // recall side bar for update unread booking count 
            }
          }, error: err => {}
        })
      }
    } else {
      let bookingtype: any;
      bookingtype = localStorage.getItem('bookingtype');
      if (bookingtype > 0) {
        this.bookingService.getBookingDocCount(bookingtype).subscribe({
          next: res => {
            if (res.data) {
              this.unreadBooking = res.data.unreadBookingCount
              this.createSideBarOption(this.navigationUrl); // recall side bar for update unread booking count
            }
          }, error: err => {}
        })
      }
    }
  }

  refactorGetBookingDocCount(type:any){
    let bookingtype: any;
    bookingtype = localStorage.getItem('bookingtype');
     this.isBookingStatus =  false
     if(bookingtype > 0){
       this.bookingService.getBookingDocCount(bookingtype).subscribe({next:res=>{
         if(res.data){
           this.isBookingStatus = type.isBooking ? type.isBooking : true
           this.unreadBooking = res.data.unreadBookingCount
         }  
       },error:err=>{
         this.isBookingStatus =  true
       }})
     }
  }


  clearStorageAndLogout() {
    localStorage.removeItem('mode');
    localStorage.removeItem('d_lay');
    localStorage.removeItem('filter');
    localStorage.removeItem('bookingVariance');
    localStorage.removeItem('data');
    localStorage.removeItem('receivedAt');
    localStorage.removeItem('showFeedBackPopUp');
    localStorage.clear();
    localStorage.setItem('isCookies', 'true');
    this.authService.logoutRedirect();
  }


  getBrokerType(data: any) {
    if (data.isBroker) {
      return 'B'
    } else {
      if (data.charterer == "Stolt Tankers") {
        return 'S'
      } else {
        return 'C'
      }
    }
  }


  OnMouseOver() {
    this.isChaterer = true
  }
  OnMouseleave() {
    this.isChaterer = false
  }


  logout() {
    this.bookingService.trackUser(0).then(res => {
      this.clearStorageAndLogout();
      this._storageService.removeDataFromStorage("isNotCompanyAssigned");
    }).catch((error) => {
      this.clearStorageAndLogout();
      this._storageService.removeDataFromStorage("isNotCompanyAssigned");
    })

  }

  loadIp() {
    this.httpClient.get(this.userIpUrl).subscribe({
      next: (value: any) => {
        this.userCountry = value.country;
        this.bookingService.trackUser(1, this.userCountry);
      },
      error: (error) => {
        this.bookingService.trackUser(1, this.userCountry);
      }
    }
    );
  }

  checkDwonloadBookingKey() {
    this.myInterval = setInterval(() => {
      this.isDownloadBookingKey = sessionStorage.getItem('downloadBookingKey');
      this.isDownloadSustainBookingKey = sessionStorage.getItem('downloadSustainBookingKey');
      if (this.isDownloadBookingKey) {
        this.downloadBookingsXLRangeWise(this.isDownloadBookingKey);
      }
      if (this.isDownloadSustainBookingKey) {
        this.downloadSustainBookingsXLRangeWise(this.isDownloadSustainBookingKey);
      }
    }, 10000);

  }



  downloadBookingsXLRangeWise(key: string) {
    let reqBody = { key: key }
    this.bookingService.downloadBookingsXLRangeWise(reqBody)
      .subscribe({
        next: (result: any) => {
          if (result.data.url) {
            sessionStorage.removeItem('downloadBookingKey');
            window.location.href = result.data.url;
          }
        }, error: (err) => {
          this.navigationService.showError(err?.error?.error?.description, '');
          sessionStorage.removeItem('downloadBookingKey');
          this.downloadSubscription.unsubscribe()
        }
      }
      )
  }

  downloadSustainBookingsXLRangeWise(key: string) {
    let reqBody = { key: key }
    this.sustainabilityService.downloadBookingsXLRangeWise(reqBody)
      .subscribe({
        next: (result: any) => {
          if (result.data.url) {
            sessionStorage.removeItem('downloadSustainBookingKey');
            window.location.href = result.data.url;
          }
        }, error: (err) => {
          this.navigationService.showError(err?.error?.error?.description, '');
          sessionStorage.removeItem('downloadSustainBookingKey');
          this.downloadSubscription.unsubscribe()
        }
      }
      )
  }



  checkDwonloadPocKey() {
    this.myInterval = setInterval(() => {
      this.isDownloadPocZipKey = sessionStorage.getItem('downloadPocZipKey');
      if (this.isDownloadPocZipKey) {
        this.manupulateDownloadDocumentsZipFile(this.isDownloadPocZipKey);
      }
    }, 10000);
  }

  manupulateDownloadDocumentsZipFile(key: string) {
    this.bookingService.downloadDocumentsZipFile(key)
      .subscribe({
        next: (result: any) => {
          if (result.data.url) {
            sessionStorage.removeItem('downloadPocZipKey');
            this._storageService.putDataInStorage("downloadCompleted", true);
            window.location.href = result.data.url;
          }
        }, error: (err: any) => {
          console.log(err)
          this._storageService.putDataInStorage("downloadCompleted", true);
          this.navigationService.docDownloadError(err, '');
          sessionStorage.removeItem('downloadPocZipKey');
          this.downloadPocSubscription.unsubscribe()
        }
      })
  }

  checkCompanyAssigned() {
    const intervalId = setInterval(() => {
      let isNotCompanyAssigned: any = this._storageService.getDataFromStorage('isNotCompanyAssigned')
      if (JSON.parse(isNotCompanyAssigned) === true) {
        this.logout()
        this.navigationService.showError("You do not have the required access. Please contact  your Stolt Tankers representative.", '');
        this.stopInterval(intervalId);
      }
    }, 1000);
  }

  stopInterval(intervalId: any) {
    clearInterval(intervalId);
  }



  checkDwonloadBookingManualKey() {
    this.myInterval = setInterval(() => {
      this.isDownloadKookingKey = sessionStorage.getItem('downloadBookingmanualKey');
      if (this.isDownloadKookingKey) {
        this.manupulateDownloadBookings(this.isDownloadKookingKey);
      }
    }, 10000);
  }

  manupulateDownloadBookings(key: any) {
    this.bookingService.downloadBookingFile(key)
      .subscribe({
        next: (result: any) => {
          if (result.data.url) {
            sessionStorage.removeItem('downloadBookingmanualKey');
            window.location.href = result.data.url;
          }
        }, error: (err: any) => {
          this.navigationService.showError(err, '');
          sessionStorage.removeItem('downloadBookingmanualKey');
          this.downloadBOokingSubscription.unsubscribe()
        }
      })
  }

  selectedOption(event: any) {
    this.pushDataToGADataLayer(event.option.path);
    if (event.option.path && event.option.path != 'logout' && event.option.path != 'api/documentation') {
      this.createSideBarOption(event.option.path);
      this.router.navigate([event.option.path]);
    } else if (event.option.path == 'api/documentation') {
      let currentUrl = (this.router.url == '/sustainability/dashboard') ? 'sustainability' : this.router.url.substring(1)
      this.createSideBarOption(currentUrl)
      window.open('api/documentation')
    } else if (event.option.path == 'logout') {
      this.logout();
    }else{
      this.logout();
    }
  }

  pushDataToGADataLayer(link:any){
    let gaData = {
      eventCategory: "user_login",
      eventAction: "page load" + ' ' + link,
      eventLabel: "page_load",
      eventValue: link,
      user_charterer: this.profileData?.isBroker ? this.profileData?.broker_short_name : this.profileData?.charterer,
      userType: this.getBrokerType(this.profileData),
      user_name: this.profileData.name ? this.profileData.name.split("(")[0].trim() : "User",
      user_id: this.profileData.user_id ? this.profileData.user_id : ""
    }
    this._googleAnaylticsService.sendPageTrackingLog(gaData)
  }


  selectedMenu(select: any, menu: any) {
    if (select == menu) {
      return true;
    }
    return false
  }

  navigationCollapse(event:any){
    this.isCollapsed = !this.isCollapsed;
    this.navigationService.isNavigationCollapseSet(this.isCollapsed);
  }

}
